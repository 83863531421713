.App {
    font-family: "proxima nova",Arial,Helvetica,sans-serif;
}

div {
    display: block;
}

.footer-container {
    position: absolute;
    height: 34px;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    background-color: #fff;

    .app-footer {
        width:100%;
        height: 100%;
        box-sizing: border-box;
        display: block;
        margin: 0 auto;
        padding: 9px 80px;

        p {
            font-size: 12px;
            margin: 0;
        }
    }
}

.auth-content {
    // Font and colors
    background-color: #fff;
    line-height: 1.4;
    font-size: 14px;
    font-family: "proxima nova",montserrat-okta,Arial,Helvetica,sans-serif;
    color: #6e6e78;


    // Border
    border: 1px solid #ddd;
    border-radius: 3px;

    // Display layout
    width: 400px;
    min-width: 300px;
    margin: 100px auto 8px;
    position: relative;
    overflow: auto;
    display: block;

    h2, h3 {
        text-align: center;
    }

    h2 {
        margin: 0;
    }

    h3 {
        // sign in label
        font-weight: 600;
        color: #5e5e5e;
        font-size: 15px;
        margin-top: 10px;
        margin-bottom: 15px;
    }

    .auth-content-inner {
        padding: 20px 42px;
    }

    .auth-header {
        border-bottom: 1px solid #ddd;
        padding: 30px 90px;
        margin: 0;
    }

    // Username/password labels
    label {
        font-size: 14px;
        font-weight: 600;
        color: #1d1d21;
    }

    .password-input {
        margin-top: 15px;
    }

    .text-input-container {
        height: 40px;
        border: 1px solid #8c8c96;
        display: inline-block;
        float: left;
        border-radius: 3px;
        width: 100%;

        input {
            width: 100%;
            height: 100%;
            padding: 6px 8px;
            line-height: 16px;
            border: none;
            background: transparent;
            font-size: 14px;
            margin: 0;
            box-sizing: border-box;
        }
    }

    .field-input-container {
        display: block;
        height: 70px;
    }

    .username-input {
        margin-bottom: 15px;
    }

    .password-input {
        margin-bottom: 15px;
    }

    .submit-button-container {
        padding: 0 0 25px;
        border: none;
        box-shadow: none;
        outline: 0;
        display: block;

        .submit-button {
            background: #1662dd !important;
            border-color: #1662dd !important;
            color: #ffffff !important;
            box-shadow: 0 1px 0 rgba(0,0,0,.15),inset 0 1px 0 0 hsla(0,0%,100%,.1);
            height: 50px;
            width: 100%;
            vertical-align: baseline;

            font-size: 14px;
            border: 1px solid;
            border-radius: 3px;
            box-sizing: border-box;
        }
    }

    .remember-me {
        display: block;
        margin-bottom: 25px;
        margin-top: 15px;
        color: #6e6e78;

        label {
            font-size: 13px;
            color: #6e6e78;
            font-weight: 400;
        }
    }

    .auth-footer {
        color: #6e6e78;
        display: flex;
        flex-direction: column;

        .link {
            color: #6e6e78;
            padding: 0.3rem;
        }
    }
}

.app-header-banner {
    background-color: #fff;
    top: 0;
    width: 100%;
    box-shadow: 0 0 2px 1px hsla(0,0%,68.6%,.3);
    text-align: center;
    padding: 20px 0;

    h1 {
        font-size: 24px;
        line-height: 26px;
        font-weight: lighter;
        margin: 0;
        padding: 0;
        outline: 0;
    }
     p {
         font-size: 14px;
         margin: 0;
         padding: 0;
         outline: 0;
     }
}

.App {
    position: absolute;
    bottom: 0;
    top: 0;
    background-color: #ebebed !important;
    width: 100%;
    color: #777;
    display: block;
}

#powered-by-okta {
    float: left;
}

#privacy-policy {
    float: right;
}
